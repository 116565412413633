import React, { ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import uniqueId from 'lodash/uniqueId';
import * as Styled from './styles';
import { ButtonType, FeatureType } from './types';
import pricingFeatureDivider from '../../assets/pricing-feature-divider.svg';
import crown from '../../assets/icon-crown.svg';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

type Price = 'annual' | 'monthly';

interface PriceData {
  name: string | ReactNode;
  price: Record<
    Price,
    {
      amount: number;
      url: string;
    }
  >;
  buttonType: ButtonType;
  featuresStyles: SerializedStyles;
  features: {
    type: FeatureType;
    description: string | ReactNode;
  }[];
}

const DATA: PriceData[] = [
  {
    name: 'Standard',
    buttonType: 'outline',
    price: {
      monthly: {
        amount: 39,
        url: 'https://checkout.sketchwow.com?add-to-cart=816352',
      },
      annual: {
        amount: 20,
        url: 'https://checkout.sketchwow.com?add-to-cart=816365',
      },
    },
    featuresStyles: css`
      margin-top: 92px;
      @media screen and (max-width: 1020px) {
        margin-top: 35px;
      }
    `,
    features: [
      // {
      //   type: 'contains',
      //   description: 'Up to 40 elements per document',
      // },
      {
        type: 'contains',
        description: 'Up to 10 pages per document',
      },
      {
        type: 'contains',
        description: 'Unlimited sketches',
      },
      {
        type: 'contains',
        description: 'AI-powered mindmaps, flows, visuals',
      },
      {
        type: 'contains',
        description: '73 templates',
      },
      {
        type: 'contains',
        description: '350 elements, shapes, icons',
      },
      {
        type: 'contains',
        description: 'Standard color backgrounds',
      },
      {
        type: 'contains',
        description: '30 fonts (+ import custom fonts)',
      },
      {
        type: 'contains',
        description: 'Resize canvas (Custom size)',
      },
      {
        type: 'contains',
        description: 'Import Images (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'JPG, PNG, SVG, PDF, Animated GIF (Export)',
      },
      {
        type: 'contains',
        description: 'Presentation mode',
      },
      {
        type: 'contains',
        description: 'File backup & restore',
      },
      {
        type: 'contains',
        description: 'Use on multiple computers',
      },
      {
        type: 'contains',
        description: 'Standard support',
      },
      // {
      //   type: 'excludes',
      //   description: 'Get access to new shapes, images & templates',
      // },
    ],
  },
  {
    name: (
      <span
        css={css`
          position: relative;

          &:after {
            content: '';
            width: 24px;
            height: 22px;
            position: absolute;
            top: -5px;
            left: -13px;
            background: url(${crown}) no-repeat 0 0;
            background-size: contain;
          }
        `}
      >
        Pro
      </span>
    ),
    buttonType: 'solid',
    price: {
      monthly: {
        amount: 69,
        url: 'https://checkout.sketchwow.com?add-to-cart=816395',
      },
      annual: {
        amount: 40,
        url: 'https://checkout.sketchwow.com?add-to-cart=816382',
      },
    },
    featuresStyles: css`
      margin-top: 36px;
    `,
    features: [
      {
        type: 'contains',
        description: (
          <div
            css={css`
              max-width: calc(100% - 51px);

              &:after {
                content: '';
                display: block;
                width: 321px;
                max-width: calc(100% + 51px);
                height: 12px;
                margin-top: 8px;
                margin-bottom: -12px;
                margin-left: -51px;
                background: url(${pricingFeatureDivider}) no-repeat 0 0;
                background-size: contain;
              }
            `}
          >
            Everything&nbsp;<strong>Standard</strong>&nbsp;has, plus:
          </div>
        ),
      },
      // {
      //   type: 'additional',
      //   description: (
      //     <div>
      //       <strong>Unlimited</strong>&nbsp;elements per document
      //     </div>
      //   ),
      // },
      {
        type: 'additional',
        description: (
          <div>
            <strong>Unlimited</strong>&nbsp;pages per document
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>Unlimited</strong>&nbsp;sketches
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>AI-powered</strong>&nbsp; mindmaps, flows, visuals
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>220+</strong>&nbsp;premium templates
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>1000+</strong>&nbsp;elements, shapes, icons
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>12</strong>&nbsp;exclusive background images
          </div>
        ),
      },
      {
        type: 'additional',
        description: (
          <div>
            <strong>90+</strong>&nbsp;Fonts (+ import custom fonts)
          </div>
        ),
      },
      {
        type: 'additional',
        description: 'Resize canvas (Custom size)',
      },
      {
        type: 'contains',
        description: 'Import Images (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'JPG, PNG, SVG, PDF, Animated GIF (Export)',
      },
      {
        type: 'contains',
        description: 'Presentation mode',
      },
      {
        type: 'contains',
        description: 'File backup & restore',
      },
      {
        type: 'contains',
        description: 'Use on multiple computers',
      },
      {
        type: 'contains',
        description: (
          <div>
            <strong>Premium</strong>&nbsp;support
          </div>
        )
        
      },
      // {
      //   type: 'additional',
      //   description: 'Get access to new shapes, images & templates',
      // },
    ],
  },
];

const SUBTITLE_BY_TYPE: Record<Price, string> = {
  annual: '(Billed Yearly)',
  monthly: '(Billed Monthly)',
};

export const Pricing = (): ReactElement => {
  const memoizedData = useMemo(
    () =>
      DATA.map((item) => ({
        ...item,
        id: uniqueId(),
        features: item.features.map((feature) => ({
          ...feature,
          id: uniqueId(),
        })),
      })),
    [],
  );

  const pricingRef = useRef(null);
  const stickyDetectionRef = useRef(null);
  
  const { isVisible } = useVisibilityDetection(pricingRef, [0.1, 0.9]);
  const { isVisible: shouldHideTopBar } = useVisibilityDetection(stickyDetectionRef, [0, 1], true);

  const [priceType, setPriceType] = useState<Price>('monthly');

  const handleBillingChange = useCallback(() => {
    setPriceType(priceType === 'annual' ? 'monthly' : 'annual');
  }, [priceType]);

  return (
    <Styled.Wrapper isVisible={isVisible} ref={pricingRef} id="pricing">

      <Styled.Switcher>
        <Styled.DealIllustration />
        <Styled.SwitcherInput checked={priceType === 'monthly'} onChange={handleBillingChange} type="checkbox" />
        <Styled.SwitcherCopy>Yearly</Styled.SwitcherCopy>
        <Styled.SwitcherUI isMonthly={priceType === 'monthly'} />
        <Styled.SwitcherCopy>Monthly</Styled.SwitcherCopy>
      </Styled.Switcher>

      <Styled.ListWrapper>
        <Styled.ListTopBackground 
          css={css`
          max-width: 960px;
        `}
        />
        <Styled.List>
          {memoizedData.map(({ buttonType, featuresStyles, features, id, name, price }) => (
            <Styled.PricingColumn key={id}>
              <Styled.Heading>
                <Styled.Title>{name}</Styled.Title>
                <Styled.SubTitle>{SUBTITLE_BY_TYPE[priceType]}</Styled.SubTitle>
                <Styled.Price>
                  <Styled.PriceVault>$</Styled.PriceVault>
                  <Styled.PriceAmount>{price[priceType].amount}</Styled.PriceAmount>
                  <Styled.PricePeriod>/mo</Styled.PricePeriod>
                </Styled.Price>
                <Styled.Button href={/*"/lifetime-plans"*/price[priceType].url} type={buttonType}>
                  Get Started
                </Styled.Button>
              </Styled.Heading>
              <Styled.Features css={featuresStyles}>
                {features.map(({ description, id: featureId, type }) => (
                  <Styled.Feature key={featureId} type={type}>
                    {description}
                  </Styled.Feature>
                ))}
              </Styled.Features>
            </Styled.PricingColumn>
          ))}
        </Styled.List>
        <Styled.ListBottomBackground 
          css={css`
          max-width: 960px;
        `}
        />
      </Styled.ListWrapper>

      <Styled.BackgroundWrapper>
        <Styled.Background>
          <Styled.BackgroundIllustrations />
        </Styled.Background>
      </Styled.BackgroundWrapper>



    </Styled.Wrapper>
  );
};
